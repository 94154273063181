import View from './view.js';
View();

/**
* @param integer val: value
* @param integer n: length of decimal
* @param integer x: length of whole part
* @param mixed   s: sections delimiter
* @param mixed   c: decimal delimiter
*/
// formatNumber(val, n, x, s, c)
// {
// 	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
// 	num = val.toFixed(Math.max(0, ~ ~n));

// 	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
// }


$('.owl-carousel').owlCarousel({
	margin: 0,
	loop: false,
	autoWidth: false,
	mouseDrag: true,
	nav: true,
	responsiveClass: true,
	responsive: {
		0: { items: 1 }
	}
});

// Plus - minu
$(document).on('click', '[data-js="plusminus"] button', function(e)
{
	var el = $(this).parent().find('input');
	var num = parseInt(el.val());
	num = $(this).hasClass('plus') ? num + 1 : num - 1;
	if(num <= 0) num = 0;
	el.val(num);
	el.trigger('change');
	e.preventDefault();
});

$(document).on('input', '[data-js="plusminus"] input', function()
{
	var num = $(this).val().replace(/[^1-9]/g, '');
	if(num.length> 1) num = num.substr(0,1);
	$(this).val(num);
	$(this).trigger('change');
});

var qty1Min;

$(document).on('keyup', '[data-js="plusminus"] input', function()
{
	var el = $(this);

	clearTimeout(qty1Min);
	qty1Min = setTimeout(function ()
	{
		if(!el.val()) el.val(1);
		el.trigger('change');
	}, 500);
});

$(document).on('keyup', '[data-js="plusminus"] input', function()
{
	var el = $(this);

	clearTimeout(qty1Min);
	qty1Min = setTimeout(function ()
	{
		if(!el.val()) el.val(1);
		el.trigger('change');
	}, 500);
});

// var data = $(input).data('ticket');
// var data = JSON.parse(data);
// data = JSON.parse('{ "name":"John", "price":30, "city":"New York"}');
// console.log(data)

// Košík

function CalcBasketSums() {
	var $inputs = $('input[data-js="add-to-basket"]');
	var $eticketSum = $('#js-eticket-sum');
	var $postSum = $('#js-post-sum');
	var basketSum = 0;
	var normalCount = 0;
	var normalSum = 0;
	var vipSum = 0;
	var vipCount = 0;
	// var tickets = {
	// 	"normal": { "price": 499, "count": 1},
	// 	"vip": { "price": 1200, "count": 1}
	// };

	$inputs.map(function(i, input)
	{
		if (input.value <= 0)
			return;

		var price = $(input).data('price') + "";
		var type = $(input).data('type');

		price = price.replace(/\s/g, '');
		price = parseInt(price);
		basketSum += price * input.value;

		if (type == "normal") {
			normalCount += parseInt(input.value);
			normalSum += price * input.value;
		}
		
		if (type == "vip") {
			vipCount += parseInt(input.value);
			vipSum += price * input.value;
		}
	});

	if (basketSum <= 0) {
		$(".js-tickets-table").hide();
	} else {
		$(".js-tickets-table").show();
	}

	if (normalCount > 0) {
		$(".js-tickets-table").find("tr.normal").show().find('.count').html(normalCount);
		$(".js-tickets-table").find("tr.normal").find('.price').html(normalSum);
	} else {
		$(".js-tickets-table").find("tr.normal").hide();
	}

	if (vipCount > 0) {
		$(".js-tickets-table").find("tr.vip").show().find('.count').html(vipCount);
		$(".js-tickets-table").find("tr.vip").find('.price').html(vipSum);
	} else {
		$(".js-tickets-table").find("tr.vip").hide();
	}

	// eTicket sum
	if ($eticketSum.length) {
		basketSum += basketSum > 0 ? window.mjls_eshop_prices.eticket : 0;
		$eticketSum.find('.num').html(basketSum);
	}

	// Post sum
	if ($postSum.length) {
		basketSum += basketSum > 0 ? window.mjls_eshop_prices.post : 0;
		$postSum.find('.num').html(basketSum);
	}
};
CalcBasketSums();

$(document).on("change", 'input[data-js="add-to-basket"]',  function() {
	CalcBasketSums();
});

// Fake radio
$(document).on("change", "input:checkbox.custom-control-input", function() {
	var $wrap = $(this).parents('.custom-switch-wrap.com');
	if (!$wrap.length)
		return;

	$wrap.removeClass('checked');
	if (this.checked)
		$wrap.addClass('checked');
});